<template>
  <div id="psychomotor" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="text"
                rules="required|min:5|max:100"
                label="Name"
                v-model="psychomotor.name"
                placeholder="Enter a name"
              />
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  psychomotor: 'psychomotor',
  data() {
    return {
      schoolId: null,
      userId: null,
      pageTitle: 'Psychomotor',
      skipQuery: true,
      psychomotor: {
        id: null,
        schoolId: null,
        name: '',
      },
    }
  },
  apollo: {
    psychomotor: {
      query: gql`
        query psychomotor($id: ID!) {
          psychomotor(id: $id) {
            id
            schoolId
            name
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    submit() {
      const name = this.psychomotor.name
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createPsychomotor($schoolId: Int!, $name: String!) {
                createPsychomotor(input: { schoolId: $schoolId, name: $name }) {
                  psychomotor {
                    id
                    name
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              name: name,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createPsychomotor.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/psychomotors`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updatePsychomotor($id: Int!, $name: String!) {
                updatePsychomotor(input: { id: $id, name: $name }) {
                  psychomotor {
                    id
                    name
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.psychomotor.id),
              name: name,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updatePsychomotor.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/psychomotors`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.userId = user.id
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'Psychomotors',
          route: `/school/${this.schoolId}/psychomotors`,
        },
      ])
    })
  },
}
</script>

<style lang="scss" scoped></style>
style
